
import React from 'react';
import Logo from './../../assets/sinder-logo.png';
import ArrowRight from './../../assets/arrow-right.png';

import { RotatingText } from 'react-simple-rotating-text';
import Phone from './../../assets/solution-1.png'

import './landingHeader.scss'
const LandingHeader = () => {
    return (
        <div className={"landing-header-container"} >
            <div className={"landing-header-top"} >
                <img className={"logo"} src={Logo} alt={"sinder-logo"} />
                <button className={"landing-header-join"} onClick={() => window.location.href = "#waitlist"}>Join the Waitlist <img src={ArrowRight} alt={"arrow-right"} /></button>
            </div>
            <div className={"landing-header-body"}>
                <div className={"landing-header-body-left"}>
                    <div className='landing-header-title-container'>
                        <span className={"landing-header-title"}>
                            Spending & Sending Money shouldn't be
                        </span>
                        <span className='landing-header-title'>
                            <RotatingText className={"marquee"} texts={['Complicated', 'Time-Consuming', 'Expensive']} />
                        </span>
                    </div>
                    <p className={"landing-header-description"}>Sign up to the waitlist to be the first to get the zero FX cashback card and access to Send Now, Pay Later.</p>
                    <button className={"landing-header-join"} onClick={() => window.location.href = "#waitlist"}>Join the Waitlist <img src={ArrowRight} alt={"arrow-right"} /></button>
                </div>
                <div className={"landing-header-body-right"}>
                    <div className={"image-container"}>
                        <img src={Phone} alt={"phone"} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LandingHeader