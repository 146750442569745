import React from "react"

import SNPL from './../../assets/snpl.png';
import Interest from './../../assets/interest.png';
import Transparent from './../../assets/transparent.png';
import Fees from './../../assets/fees.png';

import './benefit.scss'

const Benefit = () => {

    const benefits = [{
        icon: SNPL,
        title: 'Shariah-Compliant Send Now, Pay Later (SNPL)',
        content: 'We offer an alternative to traditional loans by using Murabaha principles, enabling users to send remittances backed by real asset purchases.',
    },
    {
        icon: Interest,
        title: 'No Riba (Interest)',
        content: 'We follow Islamic finance rules and ensure that no interest is charged on deferred payments.',
    },
    {
        icon: Transparent,
        title: 'Transparent Markup',
        content: 'All fees are disclosed upfront, so there are no hidden costs.',
    }, {
        icon: Fees,
        title: 'Late Fees',
        content: 'We have late fees to incentivise on time payment, however these fees are donated in line with Shariah.',
    }]

    return (

        <div className={"benefit"}>
            <div className={"benefit-left"}>
                <div className={"benefit-left-sticky"}>
                    <div className={"benefit-left-title"}>THE BENEFIT</div>
                    <div className={"benefit-left-header"}>Why Shariah-Compliant Financing?</div>
                    <div className={"benefit-left-body"}>Sinder’s financial products are built around Islamic principles, providing users with ethical and transparent financial solutions:
                    </div>
                </div>
            </div>

            <div className={"benefit-right"}>
                {benefits.map((benefit, index) => (
                    <div key={index} className={"benefit-right-item"}>
                        <img className={"benefit-right-item-icon"} src={benefit.icon} alt={"benefits"} />
                        <div className={"benefit-right-item-title"}>{benefit.title}</div>
                        <div className={"benefit-right-item-content"}>{benefit.content}</div>
                    </div>
                ))}
            </div>
        </div>

    )
}

export default Benefit;