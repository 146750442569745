import React from "react"
import Visa from './../../assets/visa-logo.png';
import Paymob from './../../assets/paymob-logo.png';
import Veriff from './../../assets/veriff-logo.png';
import DDCAP from './../../assets/ddcap-logo.png';

import './partners.scss'
import Marquee from "react-fast-marquee";

const Partners = () => {

    const partners = [{
        logo: Visa
    }, {
        logo: Paymob
    }, {
        logo: Veriff,
    }, {
        logo: DDCAP
    }
    ]

    return <React.Fragment>
        <div className={"partners"}>
            <span className={"partners-title"}>
                Our partners in success
            </span>
            <div className={"partners-container web-view"}>
                {partners.map((partner, index) => <img key={index} className={"partners-logo"} src={partner.logo} alt={"partner"} />)}
            </div>
            <div className={"partners-container mobile-view"}>
                <Marquee className={"marquee"} gradient={true} loop={0} direction={'left'} speed={100} gradientWidth={120}>
                    {[...partners, ...partners].map((partner, index) => <img key={index} className={"partners-logo"} src={partner.logo} alt={"partner"} />)}
                </Marquee>
            </div>
        </div>
    </React.Fragment>
}

export default Partners;