import React from "react"
import Email from './../../assets/email.png';
import Facebook from './../../assets/facebook.png';
import Instagram from './../../assets/instagram.png';
import Twitter from './../../assets/twitter.png';
import Linkedin from './../../assets/linkedin.png';
import SinderFooter from './../../assets/sinder-footer-logo.png';


import './footer.scss'

const Footer = () => {

    const socialMedia = [{
        icon: Facebook,
        link: 'https://www.facebook.com/profile.php?id=61566378287560&mibextid=LQQJ4d'
    }, {
        icon: Instagram,
        link: ''
    }, {
        icon: Twitter,
        link: ''
    }, {
        icon: Linkedin,
        link: 'https://www.linkedin.com/company/sinder-ae/about/'
    }]

    return (
        <div className={"footer"}>
            <div className={"footer-top"}>
                <div className={"footer-top-left"}>
                    <img src={SinderFooter} alt={"sinder-logo"} />
                    <span>Sinder - Sparking the Future of Finance.</span>
                </div>
                <div className={"footer-top-right"}>
                    <span className={"footer-top-right-header"}>
                        Have questions or want to know more about Sinder? Our team is here to help.
                    </span>
                    <div className={"footer-top-right-content"}>
                        <img className={"email-address-logo"} src={Email} alt={"email"} />
                        <span className={"email-address"}>info@sinder.ae</span>
                    </div>
                    <div className={"footer-top-right-mobile"}>
                        {socialMedia.map((entry, index) => (
                            <img key={index} className={"social-media-icon"} src={entry.icon} alt={"social-media"} />
                        ))}
                    </div>
                </div>
            </div>
            <div className={"footer-bottom"}>
                <div className={"footer-bottom-left"}>
                    <span className={"rights"}>© 2024 Sinder. All rights reserved.</span>
                    <div className={"terms"}>
                        <a className={"terms-item"} href={"/"}>Privacy Policy</a>
                        <a className={"terms-item"} href={"/"}>Terms of Service</a>
                    </div>
                </div>
                <div className={"footer-bottom-right"}>
                    {socialMedia.map((entry, index) => (
                        <a href={entry.link}><img key={index} className={"social-media-icon"} src={entry.icon} alt={"social-media"} /></a>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default Footer;