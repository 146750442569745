import React from "react";
import Marquee from "react-fast-marquee";
import './marquee.scss';

const MarqueeBanner = () => {
    return (
        <React.Fragment>
            <div className={"marquee-container"}>
                <Marquee className={"marquee"} gradient={true} loop={0} direction={'left'} speed={100} gradientWidth={120}>
                    {Array(5).fill('Stay In The Loop').map((entry, index) => (
                        <div key={index} className={"marquee-content"}>
                            <span className={"marquee-content-text"}>{entry}</span>
                            <span className={"marquee-content-symbol"}>✦</span>
                        </div>
                    ))}
                </Marquee>
            </div>
        </React.Fragment>
    )
}

export default MarqueeBanner;