import React from "react"
import Complex from './../../assets/complex-icon.png';
import TimeConsuming from './../../assets/time-consuming-icon.png';
import Expensive from './../../assets/expensive-icon.png';

import './problem.scss';

const Problem = () => {
    const problemCrumbs = [{
        icon: Complex,
        header: 'Complex',
        description: 'Multiple steps, paperwork, and confusing processes.'
    },
    {
        icon: TimeConsuming,
        header: 'Time-Consuming',
        description: 'Waiting days for transfers and facing rigid repayment terms.'
    },
    {
        icon: Expensive,
        header: 'Expensive',
        description: 'High borrowing fees, FX charges, and unpredictable costs.'
    }]


    return <React.Fragment>
        <div className={"problem"}>
            <span className={"problem-title"}>
                THE PROBLEM
            </span>
            <div className={"problem-content"}>
                <span className={"problem-content-header"}>Borrowing, Sending, and Spending Money Shouldn’t Be Hard</span>
                <p className={"problem-content-body"}>Managing finances as an expat in the UAE can be difficult. Whether it's borrowing money for emergencies, sending funds home to loved ones, or spending abroad, the process is often:</p>
            </div>
            <div className={"problem-crumbs"}>
                {problemCrumbs.map((crumb, index) => (
                    <div key={index} className={"problem-crumbs-item"}>
                        <img className={"problem-crumbs-item-icon"} src={crumb.icon} alt={"problem-icon"} />
                        <span className={"problem-crumbs-item-header"}>{crumb.header}</span>
                        <span className={"problem-crumbs-item-description"}>{crumb.description}</span>
                    </div>
                ))}
            </div>
        </div>
    </React.Fragment>
}

export default Problem;